/** HTML-Tags */
.wrapper {
  margin-top: 66px; }
  @media (max-width: 599px) {
    .wrapper {
      margin-top: 58px; } }
  .wrapper .global_progress_bar {
    position: fixed;
    top: 65px;
    width: 100%;
    z-index: 1200; }
    @media (max-width: 599px) {
      .wrapper .global_progress_bar {
        top: 57px; } }
  .wrapper ul[class^='MuiList-root'] {
    padding-top: 0;
    padding-bottom: 0; }
  .wrapper .split_screen .results {
    position: fixed;
    z-index: 1;
    top: 66px;
    left: 0;
    width: calc(100% / 3); }
    @media (max-width: 599px) {
      .wrapper .split_screen .results {
        top: 57px; } }
    @media (max-width: 959px) {
      .wrapper .split_screen .results {
        width: 100%; } }
    .wrapper .split_screen .results ~ .listing {
      margin-top: 33px;
      height: calc(100vh - 134px); }
      @media (max-width: 599px) {
        .wrapper .split_screen .results ~ .listing {
          height: calc(100vh - 126px); } }
  .wrapper .split_screen .pagination {
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
    height: 50px;
    width: calc(100% / 3 - 1px); }
    @media (max-width: 959px) {
      .wrapper .split_screen .pagination {
        width: 100%; } }

.chartdiv {
  font-family: 'Archivo Narrow';
  width: 100%;
  height: 42vh; }
  @media (max-width: 599px) {
    .chartdiv {
      height: 60vh; } }

.amcharts-period-selector-div {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; }

.amcharts-period-input-selected {
  box-sizing: border-box;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  line-height: 1.75;
  text-decoration: none;
  background-color: #4c4c4c;
  text-align: center;
  letter-spacing: 0.5px;
  cursor: pointer;
  margin: 3px;
  outline: 0;
  border: 1px solid #4c4c4c;
  color: #eeeeee;
  position: relative;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out; }

.amcharts-category-axis .amcharts-axis-label {
  cursor: pointer; }
  .amcharts-category-axis .amcharts-axis-label > tspan {
    fill: #a82e68;
    text-decoration: underline; }

.amcharts-period-input {
  box-sizing: border-box;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  line-height: 1.75;
  text-decoration: none;
  background-color: transparent;
  text-align: center;
  cursor: pointer;
  margin: 3px;
  outline: 0;
  border: 1px solid #4c4c4c;
  color: #4c4c4c;
  position: relative;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out; }
  .amcharts-period-input:hover {
    color: white !important;
    background-color: #4c4c4c;
    border: 1px transparent solid; }
  .amcharts-period-input:focus,
  .amcharts-period-input :active {
    outline: 0;
    box-shadow: none; }
  @media (max-width: 450px) {
    .amcharts-period-input.mobile {
      width: 98%;
      width: stretch; } }
  .amcharts-period-input.disabled {
    opacity: 0.25; }
